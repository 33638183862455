import React from 'react';
import Link from 'next/link';
import { ArrowRight } from 'react-feather';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { Media, mediaQuery } from '@components';
import { useMediaQuery } from '@hooks';
import {
  BannersProps,
  BrandValuesProps,
  HeroBannersProps,
  HomeStructureModel,
  ImageSliderProps,
  MegaMenuProps,
  ProductsListProps,
  Region,
  SectionType,
  SeoEventListLocation,
} from '@models';

import styles from './Home.module.scss';
import { NextSeo } from 'next-seo';
import { getRegion } from '@helpers';

const ProductsList = dynamic<ProductsListProps>(() =>
  import('@modules').then((mod) => mod.ProductsList)
);

const Banners = dynamic<BannersProps>(() =>
  import('@modules').then((mod) => mod.Banners)
);

const BrandValues = dynamic<BrandValuesProps>(() =>
  import('@modules').then((mod) => mod.BrandValues)
);

const MegaMenu = dynamic<MegaMenuProps>(() =>
  import('@modules').then((mod) => mod.MegaMenu)
);

const HeroBanners = dynamic<HeroBannersProps>(() =>
  import('@modules').then((mod) => mod.HeroBanners)
);

const ImageSlider = dynamic<ImageSliderProps>(() =>
  import('@modules').then((mod) => mod.ImageSlider)
);

const HydratedProductsList = withHydrationOnDemand({ on: ['visible'] })(
  ProductsList
);

const HydratedBanners = withHydrationOnDemand({ on: ['visible'] })(Banners);

const HydratedBrandValues = withHydrationOnDemand({ on: ['visible'] })(
  BrandValues
);

interface Props {
  homeData: HomeStructureModel | null;
}

const HomePage: NextPage<Props> = ({ homeData }: Props) => {
  const isMobile = useMediaQuery(mediaQuery.mobile);

  /**
   * It renders the sections of the home page
   * @returns A section container with a child component.
   */
  const renderHomeSections = () => {
    return homeData?.data?.map((section, index) => {
      switch (section.type) {
        case SectionType.BANNERS:
          return (
            <div className="container" key={`${index}_${section.type}`}>
              <div className={styles.SectionContainer}>
                <HydratedBanners banners={section.banner} />
              </div>
            </div>
          );
        case SectionType.BRAND_VALUES:
          return (
            <div className="container" key={`${index}_${section.type}`}>
              <div className={styles.SectionContainer}>
                <HydratedBrandValues page="home" />
              </div>
            </div>
          );
        case SectionType.PRODUCT_DEAL:
          let imgUrl = section.deal.desktop_image.urls.original;

          if (isMobile) imgUrl = section.deal.mobile_image.urls.original;

          return (
            <section
              className={styles.SectionContainer}
              key={`${index}_${section.type}`}
            >
              <div
                className={styles.DealContainer}
                style={{ backgroundColor: section.deal?.background_color }}
              >
                <div className="container">
                  <div className={styles.DealData}>
                    <Media greaterThan="laptop">
                      <header>
                        <Image
                          src={imgUrl}
                          width={150}
                          height={200}
                          objectFit="contain"
                          alt={section.deal.title}
                        />

                        <Link
                          href={`/deal/${section.deal.slug}`}
                          prefetch={false}
                        >
                          <a>
                            View All
                            <ArrowRight width={16} height={16} />
                          </a>
                        </Link>
                      </header>
                    </Media>
                    <aside>
                      <HydratedProductsList
                        listLocation={SeoEventListLocation.DEAL_PRODUCTS_LIST}
                        title={''}
                        showShimmerEffect={false}
                        withDisplayEffect={false}
                        firstCardData={{
                          imageUrl: imgUrl,
                          imageAlt: section.deal.title,
                          url: `/deal/${section.deal.slug}`,
                        }}
                        shadowColor={section.deal?.background_color}
                        slidePerView={{
                          desktop: 4,
                          mobile: 2.1,
                          tablet: 4.1,
                          laptop: 5.3,
                        }}
                        path={`/deal/${section.deal.slug}`}
                        products={section.product}
                        lazy={section.product_list.requires_auth}
                        showMore={false}
                        showSeeMoreCard={true}
                      />
                    </aside>
                  </div>
                </div>
              </div>
            </section>
          );
        case SectionType.PRODUCT_BEST_SELLER:
          return (
            <div className="container" key={`${index}_${section.type}`}>
              <section className={styles.SectionContainer}>
                <HydratedProductsList
                  listLocation={SeoEventListLocation.BEST_SELLERS}
                  showShimmerEffect={false}
                  title={section.product_list.title}
                  withDisplayEffect={index > 1}
                  dealsList={section.deals}
                  slidePerView={{
                    mobile: 2.1,
                    tablet: 4,
                    desktop: 5,
                    laptop: 5.3,
                  }}
                  path={'/best-sellers'}
                  products={section.product}
                  lazy={section.product_list.requires_auth}
                  showMore={section.product_list.show_more_button}
                  showSeeMoreCard={true}
                />
              </section>
            </div>
          );
        case SectionType.PRODUCT_RECOMMENDATION:
        case SectionType.PRODUCT_RECENTLY_VIEWED:
        case SectionType.PRODUCTS:
          return (
            <div className="container" key={`${index}_${section.type}`}>
              <section className={styles.SectionContainer}>
                <HydratedProductsList
                  listLocation={SeoEventListLocation.HOME_PRODUCTS_LIST}
                  showShimmerEffect={false}
                  withDisplayEffect={index > 1}
                  title={section.product_list.title}
                  dealsList={section.deals}
                  slidePerView={{
                    desktop: 5,
                    mobile: 2.1,
                    tablet: 4,
                    laptop: 5.3,
                  }}
                  path={section.product_list.path}
                  products={section.product}
                  lazy={section.product_list.requires_auth}
                  showMore={section.product_list.show_more_button}
                  showSeeMoreCard={section.product_list?.path?.length > 0}
                />
              </section>
            </div>
          );
      }
    });
  };

  return (
    <>
      <NextSeo
        openGraph={{
          url: process.env.NEXT_PUBLIC_SITE_URL,
          type: 'website',
          images: [
            {
              url:
                getRegion() === Region.AR_AE
                  ? process.env.NEXT_PUBLIC_SITE_URL +
                    '/images/og-image-global-ae.jpeg'
                  : process.env.NEXT_PUBLIC_SITE_URL +
                    '/images/og-image-global-qa.jpeg',
              width: 1024,
              height: 500,
              alt: '1Sell',
              type: 'image/jpeg',
            },
          ],
        }}
      />
      <div className={styles.HomeWrapper}>
        <div className="container">
          <section className={styles.HeadingContainer}>
            <div className={styles.MegaMenuContainer}>
              <MegaMenu isOnStickyNavbar={false} />
            </div>

            <div className={styles.SliderContainer}>
              {homeData && homeData.sliders && (
                <ImageSlider data={homeData?.sliders} />
              )}
            </div>

            <div className={styles.HeroImagesContainer}>
              {homeData && homeData.hero_banners && (
                <HeroBanners data={homeData?.hero_banners} />
              )}
            </div>
          </section>
        </div>
        {renderHomeSections()}
      </div>
    </>
  );
};

export default HomePage;
